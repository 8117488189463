<template>
  <div class="image-picker-widget-container" :style="itemStyles">
    <img
      v-for="(ph, idx) in photosList"
      :key="idx"
      @click="onClick(idx)"
      class="photo-item"
      :class="{
        active: activeScores.includes(idx),
        [orientation]: true,
      }"
      :src="ph.photo"
      :alt="ph.name"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import setStyles from "@/utils/setStyles";
import { updateRespond } from "@/utils/clickAction";

export default {
  name: "SvImagePickerWidget",
  props: ["widgetIdx"],
  data() {
    return {
      activeScores: [],
    };
  },
  computed: {
    ...mapGetters([
      "activeStep",
      "interaction",
      "step",
      "respond",
      "interactionId",
      "respondId",
    ]),
    widget() {
      return (
        this.activeStep &&
        this.activeStep.objects &&
        this.activeStep.objects[this.widgetIdx]
      );
    },
    itemStyles() {
      return {...setStyles(this.widget), '--active-item-background': this.selectedItemBackground};
    },
    photosList() {
      return this.widget.configs.items;
    },
    orientation() {
      return this.widget.configs.orientation;
    },
    multiselect() {
      return this.widget.configs.multiselect;
    },
    selectedItemBackground() {
      return this.widget.configs.selectedItemBackground;
    },
  },
  mounted() {
    this.$emit("hideLoader");
  },
  methods: {
    photoStyles(photo) {
      return {
        width: photo.scaleX * photo.width + "px",
      };
    },
    async onClick(value) {
      if (this.multiselect) {
        const hasValue = this.activeScores.includes(value);
        if (hasValue)
          this.activeScores = this.activeScores.filter((i) => i !== value);
        else this.activeScores.push(value);
      } else {
        this.activeScores = [value];
      }
      const selectedImages = this.activeScores
        .map((idx) => this.photosList[idx]?.name || `id${idx + 1}`)
        .join(", ");
      await this.$store.dispatch("updateRespond", {
        data: {
          widgetType: "image-picker",
          widgetName: `${this.orientation}${this.multiselect &&
            "/multiselect"}`,
          actionType: "image-picker",
          actionData: selectedImages || "none",
        },
        idx: this.widgetIdx,
        step: this.step,
      });
      updateRespond();
    },
  },
};
</script>

<style scoped>
.image-picker-widget-container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}
.photo-item.active
/* , .photo-item:hover  */
{
  cursor: pointer;
  transform: scale(1.2);
  z-index: 10;
}
.photo-item.active{
  background-color: var(--active-item-background);
}
.photo-item {
  transition: 0.2s transform;
}
.vertical {
  width: 100%;
  max-height: 100%;
}
.horizontal {
  max-width: 100%;
  height: 100%;
}
</style>
